// Custom css
import './assets/css/portal-style-new.scss'

import './assets/img/favicon.png'

import Vue from 'vue'
import axios from 'axios'

import vuetify from './plugins/vuetify'

// Setup global axios config 
Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: '/api/v1/',
      headers: {'x-requested-with': 'XMLHttpRequest'}
    })
  }
})

new Vue({
  el: '#app',
  components: {
    'dist-portal': () => import('./components/pages/DistPortal.vue'),
    'error-page': () => import('./components/pages/ErrorPage.vue'),
    'pop-up-message': () => import('./components/snippets/PopUpMessage.vue'),
  },
  mounted() {
    this.interceptAxios()
  },
  vuetify,
  methods: {
    dateToString(date) {
      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [date.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('-');
    },
    isParamsInUrl() {
      var url = window.location.href
      return url.split("?").length > 1
    },
    getParamsFromUrl() {
      return new URLSearchParams(window.location.search);
    },
    getCountryBasedOnDomain() {
      // Get the country of the website based on domain.
      // Currently only support realsam.co.uk (UK) and realsam.us (US)
      // Possible returned value us, uk or au
      let domain = window.location.hostname
      let countryDomain = domain.split(".").pop()
      let possibleCountry = ["us", "uk", "au"]
      
      if (possibleCountry.includes(countryDomain)) {
        return countryDomain
      }
      
      return "au"
    },
    formatPhoneNumber(number){
      let cleaned = number.replace(/[\D]+/g, '')

      // Matching 1 (000) 000-0000 or (000) 000-0000 format
      let match3 = cleaned.match(/^(1)?(\d{3})?(\d{3})?(\d+)/)
      
      if (match3) {
        let match = match3
        let string = ''

        if (match[1] && match[1]==='1'){
          string+= '+' + match[1] + ' '
        }

        if (match[2]){
          string+= '(' + match[2] + ') '
        }

        if (match[3]){
          string+= match[3] + '-'
        }

        if (match[4]){
          string+= match[4]
        }
        
        return string.trim()
        
      }
      
      return cleaned
    },
    axiosRequest(method, url, params, successCallback, errorCallback, finallyCallback){
      let config = {
        method: method,
        url: url,
      }

      if (method === "post") {
        // config.data must be an instance of URLSearchParams
        config.data = params
      } else if (method === "get") {
        config.params = params
      }

      this.$api.request(config)
      .then(response => {
        successCallback(response)
      })
      .catch(error => {
        console.log(error)
        errorCallback(error)
      })
      .finally(() => {
        finallyCallback()
      })
    },
    interceptAxios() {
      var self = this
      this.$api.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response) {
          // Request made and server responded
          console.log(error.response);
          if (error.response.status === 403 && error.response.headers.refresh_url) {
            // document.location.href = window.location.href;
            self.$root.$emit('popUpMessage', 'refreshSession')
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
  
        return Promise.reject(error);
      });
    }
  }
});
